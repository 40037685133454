import axios from 'axios';
import API_URL from '../global';

export const getBillConfigList = async (userId,companyId) => {
  try {
    const response = await axios.get(`${API_URL}/docuware/api/docuware-qb-configs/?user_id=${userId}&company_id=${companyId}`,{
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getPOConfigList = async (userId, companyId) => {
  try {
    const response = await axios.get(`${API_URL}/docuware/api/purchase-order-mappings/?user_id=${userId}&company_id=${companyId}`,{
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getCompanyList = async (page=1) => {
  try {
    const response = await axios.get(`${API_URL}/docuware/api/companies/?page=${page}`,{
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const createBillConfig = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/bill-config/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const updateBillConfig = async (vals, id) => {
  try {
    const response = await axios.put(`${API_URL}/docuware/api/update-bill-config/${id}`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getCompanyFeatureFlag = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/check-company-feature/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const changeFeatureFlag = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/change-company-feature/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const createOrUpdateBillConfigLine = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/create-or-update-bill-line/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getBillFieldMapping = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/get-bill-mappings/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getPurchaseOrderFieldMapping = async (vals) => {
  try {
    const response = await axios.post(`${API_URL}/docuware/api/get-po-mappings/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const updatePurchaseOrderMapping = async (vals) =>{
  try {
    const response = await axios.post(`${API_URL}/docuware/api/update-po-mappings/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const fetchQBTransactions = async () => {
  try {
    return await axios.get(`${API_URL}/docuware/api/qb-transactions`, {
      headers: {
        Authorization: `Token  ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCompanyOfUser = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/get-user-company/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQBCompanyOfUser = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/get-qb-clients/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getDWClientOfUser = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/get-dw-client/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const authLogin = async (vals) => {
  try {
    return await axios.post(`${API_URL}/api/auth/login`, vals, {'Content-Type': 'application/json'});
  } catch (err) {
    return err;
  }
};
export const getAllUsers = async (vals) => {
  try {
    return await axios.get(`${API_URL}/api/users`, {
      headers: {
        Authorization: `Token  ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const logout = async (vals) => {
  try {
    return await axios.post(`${API_URL}/api/auth/logout`, vals,{
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const checkIfQBClientExists = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/check-qb-client/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const checkIfDWClientExists = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/check-docuware-client/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};



export const updateDocuQBConfig = async (vals, id) => {
  try {
    return await axios.put(`${API_URL}/docuware/api/update-docuqb-configs/${id}`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createDocuQBConfig = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/docuware-qb-configs/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createCompany = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/companies/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createQBSettings = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/qb-settings/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const connectDocuwareClient = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/connect-docuware/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const addCompanyUser = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/add-compay-user/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const autoMatchVendors = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/automatch-vendors/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuickBooksConnectUrl = async (vals) => {
  try {
    return await axios.post(`${API_URL}/docuware/api/connect-quickbooks/`, vals, {
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};


export const getQBPurchaseOrderFieldList = async () => {
  try {
    return await axios.get(`${API_URL}/docuware/api/get-qb-po-field-list/`, {
      headers: {
        Authorization: `Token  ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getImportedPurchaseOrder = async (configId) => {
  try {
    return await axios.get(`${API_URL}/docuware/api/get-imported-po/?config_id=${configId}`, {
      headers: {
        Authorization: `Token  ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const fetchCabinetFields = async (cabinetId,companyId) => {
  try {
    return await axios.get(`${API_URL}/docuware/api/get-cabinet-fields/?file_cabinet_id=${cabinetId}&company_id=${companyId}`, {
      headers: {
        Authorization: `Token  ${localStorage.getItem('token')}`,
      },
    });
  } catch (err) {
    return err;
  }
};

export const fetchNStoreDocInDb = async (configId,startDate,endDate) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/fetch-n-store-documents/`,
      { configId,startDate, endDate },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,  
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchPurchaseOrderExportQb = async (configId,startDate,endDate) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/export-qb-po/`,
      { configId,startDate, endDate },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,  
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchDocuments = async (configId, isImportedInQb) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-documents/`,
      { configId, isImportedInQb },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const getDocuwareDomain = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-dw-serverurl/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchVendorLines = async (configId, documentIds) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-vendor-line-items/`,
      { configId, documentIds },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const importBillsToQuickBooks = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/import-bills/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const markTransactionsAsIgnored = async (configId, lines) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/ignore-transactions/`,
      { configId, lines },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchDocuwareVendors = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-docuware-vendors/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const getCompanyUsers = async (companyId) => {
  try {
    return await axios.get(
      `${API_URL}/docuware/api/get-company-users/${companyId}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const getBillDetail = async (billId) => {
  try {
    return await axios.get(
      `${API_URL}/docuware/api/bill-info/${billId}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchSavedVendorMaping = async (configId, qbCompanyId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/fetch-saved-v-mapings/`,
      { configId, qbCompanyId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};


export const createDocuSyncUser = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/api/auth/register`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const savePurchasedOrderMapping = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/save-po-mapping/`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};


export const createVendorMapping = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/create-vendor-mapping/`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const createQuickbooksVendor = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/create-qb-vendor/`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const updateBillDetails = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/update-bill`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};


export const fetchQuickBooksVendors = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-qb-vendors/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchQuickBooksGLAccounts = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-qb-gl-list/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};


export const updateBillLines = async (vals) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/update-bill-line`,
      vals,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};


export const fetchSelectedDocumentAndSync = async (lines, configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/fetch-docs-and-sync`,
      {lines,configId},
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchSelectedPOAndSync = async (poIds, configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/re-export-qb-po/`,
      {po_ids:poIds,configId},
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const fetchQuickBooksItems = async (configId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/get-qb-item-list/`,
      { configId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const syncQBVendor = async (companyId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/sync-qb-vendor/`,
      { companyId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const syncQBClass = async (companyId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/sync-qb-class/`,
      { companyId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const syncQBGl = async (companyId) => {
  try {
    return await axios.post(
      `${API_URL}/docuware/api/sync-gl-code/`,
      { companyId },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }
    );
  } catch (err) {
    return err;
  }
};

export const updateVendorLineMaping = async (updatingLines) => {
  try {
      return await axios.post(
        `${API_URL}/docuware/api/update-vendor-line-mapings/`,
        { updatingLines },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (err) {
      return err;
    }
}

export const saveVendorLineMaping = async (configId, dwVendorName, qbVendorName, lineMapings) => {
    try {
        return await axios.post(
          `${API_URL}/docuware/api/save-vendor-line-mapings/`,
          { configId, dwVendorName, qbVendorName, lineMapings },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          }
        );
      } catch (err) {
        return err;
      }
}

