import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter, isArray } from 'lodash';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
// @mui
import {
  Card,
  CardHeader,
  Table,
  Stack,
  Paper,
  Grid,
  Alert,
  Popover,
  Autocomplete,
  TableRow,
  Tooltip,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  IconButton,
  Box,
  TableContainer,
  TablePagination,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { ListHeads } from '../sections/@dashboard/user';
import { fetchDocuments, getBillConfigList, getDocuwareDomain } from '../services/api';
import DocuwareIcon from '../components/DocuwareIcon';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'dwId', label: 'DW Document Id', alignRight: false },
  { id: 'billDate', label: 'Bill Date', alignRight: false },
  { id: 'vendorName', label: 'Vendor Name', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: true },
  { id: 'txnStatus', label: 'Sync Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TransactionHistory() {
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const qbCompanyId = JSON.parse(localStorage.getItem('qbCompany')).id;
    const companyId = localStorage.getItem('userCompanyId');
    getBillConfigList(userId, companyId).then((response) => {
      prepareConfigList(response.data);
    });
    const cacheTxnHistoryList = localStorage.getItem('cacheTxnHistoryList');
    const cacheHistoryPageNo = localStorage.getItem('cacheHistoryPageNo');
    if (cacheTxnHistoryList) {
      setTransactionList(JSON.parse(cacheTxnHistoryList));
      const pageNumber = cacheHistoryPageNo || 0;
      setPage(pageNumber);
      setIsShowTable(true);
    }
    const cacheHistoryConfiguration = localStorage.getItem('cacheHistoryConfiguration');
    if (cacheHistoryConfiguration) {
      const jsonData = JSON.parse(cacheHistoryConfiguration);
      const { id, label } = jsonData;
      setConfig({ id, label });
      getDocuwareDomain(id).then((response)=>{
        if (response.data && response.data.dw_server_url) {
            setDWDomain(response.data.dw_server_url);
        }
      })
    }

    const cacheConfHistoryFileCabinetId = localStorage.getItem('cacheConfHistoryFileCabinetId');
    if (cacheConfHistoryFileCabinetId) {
      setFileCabinetId(cacheConfHistoryFileCabinetId);
    }
    setIsShowValidBillCount(false);
  }, []);

  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.label = item.name;
          return configItem;
        })
      : [];
    setConfigList(configOptions);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [DWDomain, setDWDomain] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isShowTable, setIsShowTable] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [configList, setConfigList] = useState(false);
  const [config, setConfig] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [isShowError, setIsShowError] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isShowValidBillCount, setIsShowValidBillCount] = useState(false);
  const [fileCabinetId, setFileCabinetId] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('cacheHistoryPageNo', newPage);
  };

  const prepareTransactionList = (transactions) =>
    transactions.map((txn) => ({
      id: txn.docuware_document_id,
      billId: txn.id,
      billNumber: txn.bill_no,
      accountPay: txn.acc_pay,
      vendorName: txn.vendor_name,
      terms: txn.terms,
      billDate: txn.bill_date,
      dueDate: txn.due_date,
      privateNote: txn.private_note,
      locationName: txn.location_name,
      categoryName: txn.category_name,
      itemName: txn.item_name,
      isImportedInQb: txn.is_imported_in_qb,
      amount: txn.bill_amount,
      txnStatus: txn.is_imported_in_qb ? 'Synced' : 'Sync Error',
      remarks: txn.validation_remark,
      isValidBill: txn.is_valid_bill,
      qbBillLink: txn.qb_bill_link || false,
      lineItems: txn.line_items ? txn.line_items : [],
    }));

  const handleBillRowClick = (billId) => {
    if (billId) {
      navigate(`/app/bill-info/${billId}`);
    } else {
      alert('Error');
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactionList.length) : 0;
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const filteredTxns = applySortFilter(transactionList, getComparator(order, orderBy), filterName);
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      console.log('Clicked');
      navigate('/app/bill-maping', { replace: false });
    },
  });
  const isNotFound = !filteredTxns.length && !!filterName;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleString(undefined, options).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
  };
  const { handleSubmit } = formik;

  return (
    <>
      <Helmet>
        <title> Transaction Sync </title>
      </Helmet>

      <Container style={{ width: '100%', padding: 0, maxWidth: '100%' }}>
        <Card style={{ overflow: 'visible', width: '100%', borderRadius: 5 }}>
          {isShowError && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert
                severity="error"
                onClose={() => {
                  setIsShowError(false);
                }}
              >
                {alertMessage}
              </Alert>
            </Stack>
          )}
          {isShowSuccess && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert
                severity="success"
                onClose={() => {
                  setIsShowSuccess(false);
                }}
              >
                {successMessage}
              </Alert>
            </Stack>
          )}
          <CardHeader title="Transaction History" />
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 0, ml: 3 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack style={{ marginTop: 20, marginBottom: 0, width: 800 }} spacing={2}>
                  <Grid container spacing={3}>
                    <Grid item sx={7} md={7}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={isArray(configList) ? configList : [{ label: "Loading...", id: 0 }]}
                        disabled={false}
                        size={'small'}
                        value={config || null}
                        defaultValue={null}
                        onChange={(_event, newValue) => {
                          setConfig(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label={'Select Configuration'} />}
                      />
                    </Grid>
                    <Grid item sx={1.4} md={1.4} style={{ marginRight: 10 }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                        loading={isBtnLoading}
                        onClick={() => {
                          setIsTableLoading(true);
                          setIsShowTable(true);
                          fetchDocuments(config.id, true).then((response) => {
                            if (response.status === 200) {
                              const docList = response.data.documents ? response.data.documents : [];
                              const configFileCabinetId = response.data.file_cabinet_id
                                ? response.data.file_cabinet_id
                                : false;
                              setIsShowValidBillCount(true);
                              setFileCabinetId(configFileCabinetId);
                              const preparedTxn = prepareTransactionList(docList);
                              setTransactionList(preparedTxn);
                              localStorage.setItem('cacheConfHistoryFileCabinetId', configFileCabinetId);
                              localStorage.setItem('cacheTxnHistoryList', JSON.stringify(preparedTxn));
                              localStorage.setItem('cacheHistoryConfiguration', JSON.stringify(config));
                              getDocuwareDomain(config.id).then((response)=>{
                                if (response.data && response.data.dw_server_url) {
                                    setDWDomain(response.data.dw_server_url);
                                }
                              })
                              setIsTableLoading(false);
                              setIsShowTable(true);
                            }
                          });
                        }}
                      >
                        Search
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>

          <Scrollbar>
            <br />
            {isShowTable && (
              <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                <Table>
                  <ListHeads
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={transactionList.length}
                    onRequestSort={handleRequestSort}
                  />
                  {isTableLoading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={7} style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {filteredTxns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          id,
                          billId,
                          billDate,
                          vendorName,
                          amount,
                          txnStatus,
                          remarks,
                          qbBillLink,
                          isValidBill,
                        } = row;

                        return (
                          <TableRow style={{ height: 5 }} hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <span
                                style={{ textDecoration: 'underline', color: 'darkblue', cursor: 'pointer' }}
                                onClick={() => {
                                  handleBillRowClick(billId);
                                }}
                              >
                                {id}
                              </span>
                            </TableCell>
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                {billDate ? formatDate(billDate) : ''}
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {vendorName}
                            </TableCell>

                            <TableCell align="right" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                                {amount ? Number(amount).toLocaleString(undefined, { minimumFractionDigits: 2 }) : ''}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              {isValidBill ? (
                                <Label color={(txnStatus === 'Not Synced' && 'default') || 'success'}>
                                  {sentenceCase(txnStatus)}
                                </Label>
                              ) : (
                                <Label color={(txnStatus === 'Sync Error' && 'error') || 'success'}>
                                  {sentenceCase(txnStatus)}
                                </Label>
                              )}
                            </TableCell>

                            {isDataFetched && txnStatus === 'Synced' && (
                              <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <IconButton size="large" color="inherit" onClick={(event) => event.stopPropagation()}>
                                  <Icon
                                    style={{ color: 'green', height: '25px', width: '25px' }}
                                    icon={txnStatus === 'Synced' ? 'mdi:refresh' : ''}
                                  />
                                </IconButton>
                              </TableCell>
                            )}
                            <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {qbBillLink && (
                                  <Tooltip title={'Click to View Bill in QuickBooks'} placement="top-end" arrow>
                                    <IconButton
                                      size="large"
                                      color="inherit"
                                      component="a"
                                      href={qbBillLink}
                                      target="_blank"
                                      onClick={(event) => event.stopPropagation()}
                                    >
                                      <Iconify
                                        icon={'octicon:link-16'}
                                        style={{ color: 'green', height: '20px', width: '20px' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (fileCabinetId) {
                                      const dwServerUrl = DWDomain || 'https://inceptiontech.docuware.cloud';
                                      const url = `${dwServerUrl}/DocuWare/Platform/WebClient/Integration?p=V&did=${id}&fc=${fileCabinetId}`;
                                      window.open(url);
                                    } else {
                                      alert('Can not find file cabinet from Configuration');
                                    }
                                  }}
                                >
                                  <DocuwareIcon style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                  {isNotFound && !isTableLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            {!isTableLoading && isShowTable && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={transactionList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
