import isArray from 'lodash/isArray';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Container,
  Grid,
  Card,
  CircularProgress,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ListHeads } from '../sections/@dashboard/user';
import Page from '../components/Page';
import Breadcrumbs from '../components/Breadcrumbs';
import {
  fetchCabinetFields,
  getPurchaseOrderFieldMapping,
  getDWClientOfUser,
  getQBPurchaseOrderFieldList,
  getBillConfigList,
  savePurchasedOrderMapping,
  getCompanyFeatureFlag,
  fetchQBTransactions,
  updatePurchaseOrderMapping,
} from '../services/api';
import SnackbarToast from '../components/Snackbar';

// ----------------------------------------------------------------------

export default function PurchaseOrderMapping() {
  const { id, cabinet } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isAddUrl = state?.key === 'Add';

  const [isEditOperation, setIsEditOperation] = useState(false);
  const [dwClientId, setDwClientId] = useState(false);
  const [purchaseOrderFieldList, setPurchaseOrderFieldList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrderLineFieldList, setPurchaseOrderLineFieldList] = useState([]);
  const [fileCabinet, setFileCabinet] = useState(false);
  const [configName, setConfigName] = useState(false);
  const [showError, setShowError] = useState({});
  const [dWFieldList, setDWFieldList] = useState([]);
  const [dWLineItemFieldList, setDWLineItemFieldList] = useState([]);
  const [isPOMappingEnable, setIsPOMappingEnable] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [configList, setConfigList] = useState(false);
  const [config, setConfig] = useState(false);
  const [pOTransactionId, setPOTransactionId] = useState('');
  const [configData, setConfigData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState({
    po_field_mapping: [],
    po_line_field_mapping: [],
  });

  const TABLE_HEAD = [
    { id: 'DocuwareFieldName', label: 'Docuware Field Name', alignRight: false },
    { id: 'QuickBooksFieldName', label: 'QuickBooks Field Name', alignRight: false },
  ];

  useEffect(() => {
    const poConfigId = localStorage.getItem('poConfigId');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('userCompanyId');

    let configListData = [];
    getCompanyFeatureFlag({ companyId, featureName: 'Purchase Order' }).then((response) =>
      response.data.is_enabled ? null : navigate('/app/sync-bill')
    );

    getBillConfigList(userId, companyId).then((response) => {
      configListData = response.data;
      setConfigData(configListData);
      const configOptions = configListData
        ? configListData.map((item) => {
            const configItem = {};
            configItem.id = item.id;
            configItem.label = item.name;
            configItem.fileCabinate = item.file_cabinet;
            return configItem;
          })
        : [];

      if (poConfigId) {
        const configValue = configOptions.find((i) => i.id === Number(poConfigId));
        setConfig(configValue);
        setConfigName(configValue.label);
      }

      prepareConfigList(response.data);
    });

    const cacheSyncConfiguration = localStorage.getItem('cacheSyncConfiguration');
    if (cacheSyncConfiguration) {
      const jsonData = JSON.parse(cacheSyncConfiguration);
      const { id } = jsonData;
      const { label } = jsonData;
      const { fileCabinate } = jsonData;
      setConfig({ id, label, fileCabinate });
    }

    const localFileCabinet = cabinet !== undefined ? cabinet : localStorage.getItem('fileCabinetId');
    setFileCabinet(localFileCabinet);
    setIsLoading(true);

    getDWClientOfUser({ userId, companyId }).then((response) => {
      const dwCompanyId = response.data.id || null;
      setDwClientId(dwCompanyId);
    });

    getQBPurchaseOrderFieldList().then((response) => {
      if (response.data) {
        const poFields = preparePOFieldList(response.data.purchase_order_fields || []);
        const poLineFields = preparePOFieldList(response.data.purchase_order_line_fields || []);
        setPurchaseOrderFieldList(poFields);
        setPurchaseOrderLineFieldList(poLineFields);
      }
    });

    if (!isAddUrl) {
      fetchCabinetFields(localFileCabinet, companyId).then((response) => {
        const preparedList = prepareFieldsList(response.data);
        setDWFieldList(preparedList.cabinateFieldsOptions);
        setDWLineItemFieldList(preparedList.lineItemsFieldsOptions);

        // Getting Data for Edit Option
        if (id !== undefined && id !== null) {
          getPurchaseOrderFieldMapping({ configId: id })
            .then((result) => {
              if (result.data) {
                const configOptions = configListData
                  ? configListData.map((item) => {
                      const configItem = {};
                      configItem.id = item.id;
                      configItem.label = item.name;
                      configItem.fileCabinate = item.file_cabinet;
                      return configItem;
                    })
                  : [];
                const configValue = configOptions.find((i) => i.id === Number(result.data.config_id));
                setIsLoading(false);
                setIsPOMappingEnable(true);
                setIsEditOperation(true);
                setConfig(configValue);
                setConfigName(configValue.label);
                let poFieldMapping = [];
                let poLineFieldMapping = [];
                result.data.po_field_mapping.forEach((item) => {
                  poFieldMapping = [
                    ...poFieldMapping,
                    {
                      dw_field_db_name: item.dw_field_db_name,
                      dw_field_display_name: item.dw_field_display_name,
                      qb_field_name: item.qb_field_name,
                    },
                  ];
                });
                result.data.po_line_field_mapping.forEach((item) => {
                  poLineFieldMapping = [
                    ...poLineFieldMapping,
                    {
                      dw_field_db_name: item.dw_field_db_name,
                      dw_field_display_name: item.dw_field_display_name,
                      qb_field_name: item.qb_field_name,
                    },
                  ];
                });
                setPurchaseOrderData({ po_field_mapping: poFieldMapping, po_line_field_mapping: poLineFieldMapping });
              }
              if (result.message) {
                setIsLoading(false);
                setOpenAlert(true);
                setErrorMessage(result.message);
              }
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      });
    }

    fetchQBTransactions()
      .then((response) => {
        prepareQBTransactionList(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const prepareQBTransactionList = (data) => {
    const transactionList = data
      ? data.map((item) => {
          const transactionItem = {};
          transactionItem.id = item.id;
          transactionItem.label = item.transaction_name;
          return transactionItem;
        })
      : [];
    setPOTransactionId(transactionList.find((i) => i.label === 'Purchase Order').id);
  };
  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.id;
          configItem.label = item.name;
          configItem.fileCabinate = item.file_cabinet;
          return configItem;
        })
      : [];
    setConfigList(configOptions);
  };

  const prepareFieldsList = (data) => {
    let lineItemsFieldsOptions = [];
    const results = data
      ? data.map((item) => {
          const cabinateFieldItem = {};
          if (Object.hasOwn(item, 'table_field_cols')) {
            const lineItemFields = item.table_field_cols.map((i) => ({
              id: i.db_name,
              label: i.display_name,
              isLineItem: true,
            }));
            lineItemsFieldsOptions = lineItemFields;
          } else {
            cabinateFieldItem.id = item.db_name;
            cabinateFieldItem.label = item.display_name;
            cabinateFieldItem.isLineItem = false;
          }
          return cabinateFieldItem;
        })
      : [];
    // remove blank object
    const cabinateFieldsOptions = results.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
    return { cabinateFieldsOptions, lineItemsFieldsOptions };
  };

  const updatePurchaseOrderData = (qbValue, dwFieldId, dwFieldLabel, type) => {
    if (type === 'lineItem') {
      if (purchaseOrderData.po_line_field_mapping.find((i) => i.dw_field_db_name === dwFieldId)) {
        purchaseOrderData.po_line_field_mapping.find((i) => i.dw_field_db_name === dwFieldId).qb_field_name = qbValue;
        setPurchaseOrderData({ ...purchaseOrderData, po_line_field_mapping: purchaseOrderData.po_line_field_mapping });
      } else {
        setPurchaseOrderData({
          ...purchaseOrderData,
          po_line_field_mapping: [
            ...purchaseOrderData.po_line_field_mapping,
            {
              dw_field_db_name: dwFieldId,
              dw_field_display_name: dwFieldLabel,
              qb_field_name: qbValue,
            },
          ],
        });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (purchaseOrderData.po_field_mapping.find((i) => i.dw_field_db_name === dwFieldId)) {
        purchaseOrderData.po_field_mapping.find((i) => i.dw_field_db_name === dwFieldId).qb_field_name = qbValue;
        setPurchaseOrderData({ ...purchaseOrderData, po_field_mapping: purchaseOrderData.po_field_mapping });
      } else {
        setPurchaseOrderData({
          ...purchaseOrderData,
          po_field_mapping: [
            ...purchaseOrderData.po_field_mapping,
            {
              dw_field_db_name: dwFieldId,
              dw_field_display_name: dwFieldLabel,
              qb_field_name: qbValue,
            },
          ],
        });
      }
    }
  };

  const preparePOFieldList = (data) => {
    const fieldList = data
      ? data.map((item) => {
          const fieldItem = {};
          fieldItem.id = item.id;
          fieldItem.label = item.label;
          return fieldItem;
        })
      : [];
    return fieldList;
  };

  const savePOMapping = (fieldMappings) => {
    let qbCompanyId = null;

    if (localStorage.getItem('qbCompany') !== undefined && localStorage.getItem('qbCompany') !== null) {
      qbCompanyId = JSON.parse(localStorage.getItem('qbCompany')).id;
    }

    const docuQbConfigValues = {
      name: configName,
      file_cabinet: fileCabinet,
      qb_company: qbCompanyId,
      dw_client: dwClientId,
      qb_transaction: pOTransactionId,
      user: localStorage.getItem('userId'), // TODO : fetch logged in employee id here
    };

    const updateConfigValues = {
      configId: String(config.id),
      poMappings: fieldMappings,
    };

    if (isEditOperation) {
      updatePurchaseOrderMapping(updateConfigValues).then((response) => {
        console.log(response);
        navigate('/app/sync-bill');
      });
    } else {
      const poConfigId = isAddUrl ? config.id : localStorage.getItem('poConfigId');
      const configValues = { ...fieldMappings, docu_qb_config_id: poConfigId };
      savePurchasedOrderMapping(configValues).then((response) => {
        console.log('response from save api : ', response);
        navigate('/app/sync-bill');
      });
    }
  };

  const navigates = [];
  if (id) {
    navigates.push({ path: '/app/purchase-order-config-list', name: 'Purchase Order Mapping' });
  } else {
    navigates.push({ path: '/quickbooks/quickbooks_code/', name: 'Configuration' });
  }
  if (!id) {
    navigates.push({ path: '/app/config', name: 'DocuWare Configuration' });
    navigates.push({ path: '/app/bill-maping', name: 'Configure Bills Transaction' });
  }
  navigates.push({ path: '', name: 'Configure Purchase Order Transaction' });

  const getPoFieldValue = (id) => {
    const qbFieldName = purchaseOrderData.po_field_mapping.find((i) => i.dw_field_db_name === id)?.qb_field_name;
    return purchaseOrderFieldList.find((i) => i.id === qbFieldName)?.label;
  };

  const getPoLineItemValue = (id) => {
    const qbFieldName = purchaseOrderData.po_line_field_mapping.find((i) => i.dw_field_db_name === id)?.qb_field_name;
    return purchaseOrderLineFieldList.find((i) => i.id === qbFieldName)?.label;
  };

  const getFileCabinate = () => {
    const companyId = localStorage.getItem('userCompanyId');

    fetchCabinetFields(config.fileCabinate, companyId).then((response) => {
      const preparedList = prepareFieldsList(response.data);
      setDWFieldList(preparedList.cabinateFieldsOptions);
      setDWLineItemFieldList(preparedList.lineItemsFieldsOptions);

      // Getting Data for Edit Option
      if (id !== undefined && id !== null) {
        getPurchaseOrderFieldMapping({ configId: id })
          .then((result) => {
            if (result.data) {
              const configOptions = configData
                ? configData.map((item) => {
                    const configItem = {};
                    configItem.id = item.id;
                    configItem.label = item.name;
                    configItem.fileCabinate = item.file_cabinet;
                    return configItem;
                  })
                : [];
              const configValue = configOptions.find((i) => i.id === Number(result.data.config_id));
              setIsLoading(false);
              setIsPOMappingEnable(true);
              setIsEditOperation(true);
              setConfig(configValue);
              setConfigName(configValue.label);
              let poFieldMapping = [];
              let poLineFieldMapping = [];
              result.data.po_field_mapping.forEach((item) => {
                poFieldMapping = [
                  ...poFieldMapping,
                  {
                    dw_field_db_name: item.dw_field_db_name,
                    dw_field_display_name: item.dw_field_display_name,
                    qb_field_name: item.qb_field_name,
                  },
                ];
              });
              result.data.po_line_field_mapping.forEach((item) => {
                poLineFieldMapping = [
                  ...poLineFieldMapping,
                  {
                    dw_field_db_name: item.dw_field_db_name,
                    dw_field_display_name: item.dw_field_display_name,
                    qb_field_name: item.qb_field_name,
                  },
                ];
              });
              setPurchaseOrderData({ po_field_mapping: poFieldMapping, po_line_field_mapping: poLineFieldMapping });
            }
            if (result.message) {
              setIsLoading(false);
              setOpenAlert(true);
              setErrorMessage(result.message);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    });
  };
  return (
    <Page title="Transaction Config">
      <SnackbarToast open={openAlert} handleClose={() => setOpenAlert(false)} message={errorMessage} type="error" />
      <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
        {!isAddUrl && <Breadcrumbs separator=">" navigates={navigates} />}
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 3, mt: 3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Configure Purchase Order Transaction
            </Typography>
            <Stack style={{ marginTop: 20, width: '100%' }} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={isArray(configList) ? configList : [{ label: 'Loading...', id: 0 }]}
                        size={'small'}
                        value={config || null}
                        defaultValue={null}
                        onChange={(event, newValue) => {
                          setConfig(newValue);
                          setConfigName(newValue.label);
                          setShowError({ ...showError, configName: '' });
                          localStorage.setItem('configId', newValue.id);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label={'Select Configuration'} />}
                        style={{ border: showError.configName ? '1px solid #D43F3E' : '', borderRadius: 6 }}
                      />
                      {showError.configName && (
                        <span style={{ color: '#D43F3E', fontSize: 12 }}>{showError.configName}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {isEditOperation === false && (
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                          onClick={() => {
                            if (config) {
                              // eslint-disable-next-line no-unused-expressions
                              isAddUrl && getFileCabinate();
                              setIsPOMappingEnable(true);
                            } else {
                              let error = { configName: '' };
                              // eslint-disable-next-line no-unused-expressions
                              configName ? null : (error = { ...error, configName: 'Configuration Name is required' });
                              setShowError(error);
                            }
                          }}
                        >
                          Next
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Container>
        </Stack>
      </Card>
      {id && !isPOMappingEnable && isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ margin: 10 }} />
        </div>
      )}
      {isPOMappingEnable && (
        <Card variant="outlined" sx={{ mt: 3 }} style={{ overflow: 'visible', borderRadius: 7 }}>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 10, mt: 3 }}>
            <Container style={{ maxWidth: '100%' }}>
              {dWFieldList.length ? (
                <>
                  <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                    <Table>
                      <ListHeads headLabel={TABLE_HEAD} rowCount={dWFieldList.length} />
                      <TableBody>
                        {dWFieldList.map((row) => {
                          const { id, label } = row;
                          return (
                            <TableRow style={{ height: 4 }} hover key={id} tabIndex={-1}>
                              <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                {label}
                              </TableCell>
                              <TableCell>
                                {id === 'DOCUMENT_TYPE' ? (
                                  'Purchase Order'
                                ) : (
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={purchaseOrderFieldList}
                                    disabled={false}
                                    size={'small'}
                                    value={getPoFieldValue(id)}
                                    defaultValue={null}
                                    onChange={(_event, newValue) => {
                                      updatePurchaseOrderData(newValue.id, id, label);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label={'QB Field'} />}
                                    style={{
                                      border: showError.transaction ? '1px solid #D43F3E' : '',
                                      borderRadius: 6,
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {Boolean(dWLineItemFieldList.length) && (
                          <Grid item xs={5} style={{ margin: 15 }}>
                            <b>Line Items</b>
                          </Grid>
                        )}
                        {dWLineItemFieldList.map((row) => {
                          const { id, label } = row;
                          return (
                            <>
                              <TableRow style={{ height: 4 }} hover key={id} tabIndex={-1}>
                                <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                                  {label}
                                </TableCell>
                                <TableCell>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={purchaseOrderLineFieldList}
                                    disabled={false}
                                    size={'small'}
                                    value={getPoLineItemValue(id)}
                                    defaultValue={null}
                                    onChange={(_event, newValue) => {
                                      updatePurchaseOrderData(newValue.id, id, label, 'lineItem');
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label={'QB Field'} />}
                                    style={{
                                      border: showError.transaction ? '1px solid #D43F3E' : '',
                                      borderRadius: 6,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    style={{
                      maxWidth: '100px',
                      minWidth: '100px',
                      maxHeight: '40px',
                      minHeight: '40px',
                      marginTop: 40,
                    }}
                    onClick={() => {
                      savePOMapping(purchaseOrderData);
                    }}
                  >
                    Save
                  </LoadingButton>
                </>
              ) : (
                <div style={{ textAlign: 'center' }}>No Cabinate Field Available.</div>
              )}
            </Container>
          </Stack>
        </Card>
      )}
    </Page>
  );
}
