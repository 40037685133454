import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import isArray from 'lodash/isArray';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import filter from 'lodash/filter';
import parseInt from 'lodash/parseInt';

import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Box,
  Tooltip,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';

import Scrollbar from '../components/scrollbar';
import { ListHeads } from '../sections/@dashboard/user';
import {
  fetchPurchaseOrderExportQb,
  fetchSelectedPOAndSync,
  getCompanyFeatureFlag,
  getImportedPurchaseOrder,
  getPOConfigList,
  getDocuwareDomain
} from '../services/api';
import Label from '../components/label';
import Iconify from '../components/iconify';
import DocuwareIcon from '../components/DocuwareIcon';


const SmallDateRangePicker = styled(DatePicker)(() => ({
  '& .MuiIconButton-root': {
    padding: '4px',
    height: '12px',
  },
  '& .MuiIconButton-root .MuiSvgIcon-root': {
    fontSize: '17px',
  },
  '& .MuiInputBase-input': {
    padding: '14px',
    fontSize: '10pt',
    height: '12px',
    width: '135px',
  },
}));

const TABLE_HEAD = [
  { id: '', label: '', alignRight: false },
  { id: 'po_number', label: 'PO No', alignRight: false },
  { id: 'txn_date', label: 'Transaction Date', alignRight: false },
  { id: 'total_amount', label: 'Total Amount', alignRight: true },
  { id: 'vendor_name', label: 'Vendor Name', alignRight: false },
  { id: 'po_status', label: 'PO Status', alignRight: false },
  { id: '', label: '', alignRight: false },
  // { id: '', label: 'Action', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PoTransactions() {
  const navigate = useNavigate();

  const currentDate = new Date();
  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const endDay = `0${currentDate.getDate()}`.slice(-2);
  const endMonth = currentDate.getMonth() + 1; // (January gives 0)
  const endYear = currentDate.getFullYear();

  const startDay = `0${thirtyDaysAgo.getDate()}`.slice(-2);
  const startMonth = `0${thirtyDaysAgo.getMonth() + 1}`.slice(-2);
  const startYear = thirtyDaysAgo.getFullYear();

  const [startDate, setStartDate] = useState(dayjs(`${startMonth}/${startDay}/${startYear}`, 'MM/DD/YYYY'));
  const [startDateStr, setStartDateStr] = useState(`${startMonth}/${startDay}/${startYear}`);
  const [endDate, setEndDate] = useState(dayjs(`${endMonth}/${endDay}/${endYear}`, 'MM/DD/YYYY'));
  const [endDateStr, setEndDateStr] = useState(`${endMonth}/${endDay}/${endYear}`);
  const [config, setConfig] = useState(false);
  const [configList, setConfigList] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [order, setOrder] = useState('asc');
  const [DWDomain, setDWDomain] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [filterValueDateFrom, setFilterValueDateFrom] = useState(dayjs(startDateStr));
  const [filterValueDateTo, setFilterValueDateTo] = useState(dayjs(endDateStr));
  const [openFilter, setOpenFilter] = useState(null);
  const [showFilterError, setShowFilterError] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [fileCabinetId, setFileCabinetId] = useState(false);
  const [poTransactionsData, setPoTransactionsData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('userCompanyId');

    getCompanyFeatureFlag({ companyId, featureName: 'Purchase Order' }).then((response) =>
      response.data.is_enabled ? null : navigate('/app/sync-bill')
    );

    getPOConfigList(userId, companyId).then((response) => {
      const confList = prepareConfigList(response.data);
      setConfigList(confList);
    });

    const cachePOConfiguration = localStorage.getItem('cachePOConfiguration');
    if (cachePOConfiguration) {
      const jsonData = JSON.parse(cachePOConfiguration);
      const { id } = jsonData;
      const { label } = jsonData;
      const { fileCabinet } = jsonData;
      setFileCabinetId(fileCabinet);
      setConfig({ id, label });
      getDocuwareDomain(id).then((response)=>{
        if (response.data && response.data.dw_server_url) {
            setDWDomain(response.data.dw_server_url);
        }
      })
      getImportedPurchaseOrder(id).then((response) => {
        if (response.data) {
          setPoTransactionsData(response.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isFilterDate && openFilter === null) {
      const newData = filteredTxns.map((i) => {
        const dateFrom = dayjs(filterValueDateFrom).format('YYYY-MM-DD');
        const dateTo = dayjs(filterValueDateTo).format('YYYY-MM-DD');
        const d1 = dateFrom.split('-');
        const d2 = dateTo.split('-');
        const c = i.txn_date.split('-');
        const from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
        const to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
        const check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

        return { ...i, filterValid: check > from && check < to };
      });
      setFilteredData(newData.filter((i) => i.filterValid === true) || []);
    }
  }, [isFilterDate, openFilter]);

  const filteredTxns = applySortFilter(poTransactionsData, getComparator(order, orderBy), filterName);

  const handleClear = () => {
    setIsFilterDate(false);
    setFilterValueDateFrom(dayjs(startDateStr));
    setFilterValueDateTo(dayjs(endDateStr));
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const prepareConfigList = (data) => {
    const configOptions = data
      ? data.map((item) => {
          const configItem = {};
          configItem.id = item.docu_qb_config;
          configItem.label = item.docu_qb_config_name;
          configItem.fileCabinet = item.file_cabinet;
          return configItem;
        })
      : [];
    return configOptions;
  };

  const handleCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const refreshData = (configValue) => {
    setConfig(configValue);
    getImportedPurchaseOrder(configValue.id).then((response) => {
      if (response.data) {
        setPoTransactionsData(response.data);
      }
      if (response.status === 200) {
        localStorage.setItem('cachePOConfiguration', JSON.stringify(configValue));
        getDocuwareDomain(configValue.id).then((response)=>{
          if (response.data && response.data.dw_server_url) {
              setDWDomain(response.data.dw_server_url);
          }
        })
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    localStorage.setItem('cachePOPageNo', newPage);
  };

  const handleOpenFilter = (event) => {
    setOpenFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setOpenFilter(null);
  };

  return (
    <div>
      <Helmet>
        <title> Purchase Order Transactions </title>
      </Helmet>
      <Container style={{ width: '100%', maxWidth: '100%' }}>
        <Card style={{ overflow: 'visible', width: '100%', borderRadius: 5 }}>
          {isShowAlert && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert
                severity="error"
                onClose={() => {
                  setIsShowAlert(false);
                }}
              >
                {'alertMessage'}
              </Alert>
            </Stack>
          )}
          <CardHeader title="Purchase Order Transactions" />
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 1, ml: 3 }}>
            <Stack style={{ marginTop: 20, marginBottom: 10, width: 900 }} spacing={2}>
              <Grid container spacing={2}>
                <Grid item sx={6} md={5}>
                  <div style={{ marginTop: 10 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={isArray(configList) ? configList : [{ label: 'Loading...', id: 0 }]}
                      size={'small'}
                      value={config || null}
                      defaultValue={null}
                      onChange={(event, newValue) => {
                        localStorage.setItem('configId', newValue.id);
                        setFileCabinetId(newValue.fileCabinet || null);
                        refreshData(newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} label={'Select Configuration'} />}
                    />
                  </div>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Scrollbar>
            <Stack
              style={{
                marginBottom: 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              spacing={1.5}
            >
              <div style={{ display: 'flex', marginLeft: 2 }}>
                {Boolean(config) && (
                  <Grid item sx={1.4} md={1.4} style={{ marginLeft: 5 }}>
                    <Button
                      style={{ marginTop: 15 }}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      sx={{ borderColor: 'grey' }}
                      onClick={() => {
                        if (Array.isArray(selectedRows) && selectedRows.length) {
                          alert(
                            'Fetching Transaction may take time, Please click on refresh after sometime.'
                          );
                          // convert all element of array in string
                          fetchSelectedPOAndSync(selectedRows.map(String), config.id)
                              .then((response) => {
                                if (response.data.status === 'Success') {
                                  refreshData(config)
                                }
                              })
                              .catch(() => {});
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    >
                      <Icon style={{ color: '#2E3192', height: '20px', width: '18px' }} icon="mingcute:download-line" />
                      <span style={{ fontSize: 15, marginLeft: 5 }}>
                        {Array.isArray(selectedRows) && selectedRows.length ? 'Re-Fetch' : 'Fetch'}
                      </span>
                    </Button>
                  </Grid>
                )}
                {/* {Array.isArray(selectedRows) && selectedRows.length > 0 && (
                  <Grid item sx={1.5} md={1.5} style={{ marginLeft: 5 }}>
                    <Button
                      style={{ marginTop: 15 }}
                      size="small"
                      color="inherit"
                      variant="outlined"
                      sx={{ borderColor: 'grey' }}
                      onClick={() => {}}
                    >
                      <Icon
                        style={{ color: '#D43F3E', height: '20px', width: '18px' }}
                        icon="flat-color-icons:cancel"
                      />
                      <span style={{ fontSize: 15, marginLeft: 5 }}>Ignore</span>
                    </Button>
                  </Grid>
                )} */}
                {/* <Grid item sx={1.4} md={1.4} style={{ marginLeft: 5 }}>
                  <Button
                    style={{ marginTop: 15 }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: 'grey' }}
                    onClick={() => {}}
                  >
                    <Icon style={{ color: 'green', height: '20px', width: '18px' }} icon="ion:sync-sharp" />
                    <span style={{ fontSize: 15, marginLeft: 5 }}>Sync</span>
                  </Button>
                </Grid>  */}
              </div>
              {Boolean(filteredTxns.length) && (
                <div style={{ display: 'flex' }}>
                  {isFilterDate && (
                    <>
                      <div style={{ fontSize: 12, marginTop: 10 }}>
                        {dayjs(filterValueDateFrom).format('MM/DD/YYYY')} -{' '}
                        {dayjs(filterValueDateTo).format('MM/DD/YYYY')}
                      </div>
                      <span
                        style={{ fontSize: 12, marginTop: 10, marginLeft: 10, color: '#D43F3E', cursor: 'pointer' }}
                        onClick={handleClear}
                      >
                        Clear
                      </span>
                    </>
                  )}
                  <span style={{ fontSize: 12, marginTop: 10, marginLeft: 10 }}>Filter By Date</span>
                  <IconButton color="primary" onClick={handleOpenFilter} style={{ justifyContent: 'end' }}>
                    <Iconify icon="ion:filter-sharp" />
                  </IconButton>
                </div>
              )}
            </Stack>
            <TableContainer sx={{ width: '100%', minWidth: 800 }}>
              {isFetching && <LinearProgress />}
              <Table>
                <ListHeads order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
                <TableBody>
                  {poTransactionsData.length ? (
                    (isFilterDate ? filteredData : filteredTxns).map((transaction) => (
                      <TableRow
                        style={{ height: 4 }}
                        hover
                        tabIndex={-1}
                        key={transaction.purchase_order_id}
                        role="checkbox"
                      >
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                          <Checkbox
                            checked={selectedRows.includes(transaction.purchase_order_id)}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                              handleCheckboxChange(transaction.purchase_order_id);
                            }}
                          />
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5, textAlign: 'center' }}>
                          <span
                            style={{ textDecoration: 'underline', color: 'darkblue', cursor: 'pointer' }}
                            onClick={() => {
                              const poDetailsData = poTransactionsData.find(
                                (i) => i.purchase_order_id === transaction.purchase_order_id
                              );
                              localStorage.setItem('poDetailsData', JSON.stringify(poDetailsData));
                              navigate(`/app/po-transactions-details/${transaction.purchase_order_id}`);
                            }}
                          >
                            {transaction.po_number}
                          </span>
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                          {transaction.txn_date}
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5, textAlign: 'right' }}>
                          {transaction.total_amount
                            ? Number(transaction.total_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            : ''}
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                          {transaction.vendor_name}
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                          <Label color={'default'}>{transaction.po_status}</Label>
                        </TableCell>
                        {/* <TableCell align="left" style={{ paddingTop: 5, paddingBottom: 5 }}>
                        Delete
                      </TableCell> */}
                        <TableCell
                          align="left"
                          style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {transaction.qb_po_link && (
                              <Tooltip title={'Click to View PO in QuickBooks'} placement="top-end" arrow>
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  component="a"
                                  href={transaction.qb_po_link}
                                  target="_blank"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <Iconify
                                    icon={'octicon:link-16'}
                                    style={{ color: 'green', height: '20px', width: '20px' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                event.stopPropagation();
                                if (fileCabinetId) {
                                  const dwServerUrl = DWDomain || 'https://inceptiontech.docuware.cloud';
                                  const url = `${dwServerUrl}/DocuWare/Platform/WebClient/Integration?p=V&did=${transaction.dw_doc_id}&fc=${fileCabinetId}`;
                                  window.open(url);
                                } else {
                                  alert('Can not find file cabinet from Configuration');
                                }
                              }}
                            >
                              <DocuwareIcon style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Data Found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {Boolean(poTransactionsData.length) && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={poTransactionsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Scrollbar>
        </Card>
      </Container>
      <Menu
        id="basic-menu"
        anchorEl={openFilter}
        open={!!openFilter}
        onClose={handleCloseFilter}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{ margin: 20 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex' }}>
              <div>
                <DemoContainer components={['SmallDateRangePicker']}>
                  <SmallDateRangePicker
                    label="Start Date"
                    value={filterValueDateFrom || null}
                    onChange={(e) => {
                      setFilterValueDateFrom(e);
                    }}
                  />
                </DemoContainer>
              </div>
              <div style={{ marginLeft: 10 }}>
                <DemoContainer components={['SmallDateRangePicker']}>
                  <SmallDateRangePicker
                    label="End Date"
                    value={filterValueDateTo || null}
                    onChange={(e) => {
                      const a = dayjs(filterValueDateFrom).format('YYYY-MM-DD');
                      const b = dayjs(e).format('YYYY-MM-DD');
                      if (dayjs(a).diff(dayjs(b)) > 0) {
                        setShowFilterError(true);
                      } else {
                        setShowFilterError(false);
                      }
                      setFilterValueDateTo(e);
                    }}
                  />
                </DemoContainer>
              </div>
            </div>
            {showFilterError && (
              <span style={{ color: 'red', fontSize: 12, marginTop: 5 }}>End Date must be bigger then Start Date</span>
            )}
          </LocalizationProvider>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ marginTop: 15 }}
              size="small"
              color="inherit"
              disabled={showFilterError}
              variant="outlined"
              sx={{ borderColor: 'grey' }}
              onClick={() => {
                setIsFilterDate(true);
                setOpenFilter(null);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </Menu>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={openModal}>
          <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5 }}>
            <div style={{ display: 'flex', margin: 20 }}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['SmallDateRangePicker']}>
                    <SmallDateRangePicker
                      label="Start"
                      value={startDate || null}
                      onChange={(e) => {
                        const jsDate = e.toDate();
                        const day = jsDate.getDate();
                        const month = jsDate.getMonth() + 1;
                        const year = jsDate.getFullYear();
                        setStartDate(e);
                        setStartDateStr(`${month}/${day}/${year}`);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div style={{ marginLeft: 20 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['SmallDateRangePicker']}>
                    <SmallDateRangePicker
                      label="End"
                      value={endDate || null}
                      onChange={(e) => {
                        const jsDate = e.toDate();
                        const day = jsDate.getDate();
                        const month = jsDate.getMonth() + 1;
                        const year = jsDate.getFullYear();
                        setEndDate(e);
                        setEndDateStr(`${month}/${day}/${year}`);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            <LoadingButton
              onClick={() => {
                alert(
                  'Fetching Transaction from QuickBooks may take time, Re-visit "PO Transaction" after few minutes.'
                );
                setIsFetching(true);
                setOpenModal(false);
                fetchPurchaseOrderExportQb(config.id, startDateStr, endDateStr).then((response) => {
                  if (response.data.status === 'Success') {
                    getImportedPurchaseOrder(config.id).then((response) => {
                      if (response.data) {
                        setPoTransactionsData(response.data);
                        setIsFetching(false);
                      }
                    });
                  }
                  setIsFetching(false);
                });
              }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{
                maxWidth: '100px',
                minWidth: '100px',
                maxHeight: '40px',
                minHeight: '40px',
                marginLeft: '35%',
                marginBottom: 20,
                marginTop: 10,
              }}
              loading={false}
            >
              Fetch
            </LoadingButton>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
